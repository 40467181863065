.loan-amt {
    display: inline-flex !important;
    width: 200px !important;
    position: relative;
    margin-left: 1rem;
}

.loan-tenure {
    display: inline-flex !important;
    width: 200px !important;
    // position: absolute !important;
}

.loan-amt > .range-wrapper {
    margin-top: 5px;
    width: 100%;
}

.loan-amt > .range-wrapper input {
    width: 100%;
    margin: 0;
    padding: 0;
}

.loan-amt > .range-wrapper > .label {
    display: block;
}

.loan-amt > .range-wrapper > .label > .amt-min {
    float: left;
    display: inline-block;
    font-size: 10px;
}

.loan-amt > .range-wrapper > .label > .amt-max {
    float: right;
    display: inline-block;
    font-size: 10px;   
}

@media (max-width: 992px) {
    .loan-tenure {

    }
}

@media (max-width: 767px) {
    .loan-amt {
        width: 100% !important;
        margin: 0 !important;
    }
}