@import "../../colors.scss";
$BoldLabelColor: #002559;
$tncColor: #283f74;
$ErrorColor: #ee495d;
$validColor: #4d9400;
$inputFocusColor: #000000;

$checkMarkBlue: #002559;
$verifyChecked: #4d9400;
$BoxCheckedColor: #002559;
$borderColor: #cccccc;
$BgColor: #f1f1f1;

$InProgressColor: #ffe1ae;
$CompletedColor: rgba(184, 233, 134, 0.5);
$NotCompleted: #c9c9c9;
$boxShadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

main {
  background-color: $BgColor;
}

hr {
  margin: 30px 0;
  width: calc(100% - 25px);
  border-top: 1px solid $borderColor;
}

.verify-identity-section,
.submit-more-info-section,
.receive-money-section,
.amendments-section {
  .container {
    .section-description {
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 1em;
    }
    .section-info-description-list {
      padding: 0;
      margin-bottom: 0;
      li {
        font-size: 14px;
        list-style-type: none;
        .fa-check {
          margin: 0 12px;
          color: $BoxCheckedColor;
        }
      }
    }
    .row {
      position: relative;
      &.mb-responsive {
        @media (max-width: 768px) {
          margin: 0 !important;
          flex-direction: column;
          .col {
            max-width: 100% !important;
            margin: 1rem 0 !important;
            padding: 0;
            flex: 1 1 auto;
          }
        }
      }
      .bg-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1050;
        background-color: rgba(0, 0, 0, 0.8);
      }
      .col {
        &.index-0 {
          z-index: 0 !important;
        }
        &.verification-col {
          display: flex;
          align-items: center;
          .verify-code-success {
            font-size: 14px;
            font-weight: 600;
            color: $verifyChecked;
            margin: 0 10px 0 0;
          }
          .fa-check {
            color: $verifyChecked;
          }
        }
        .info-alert {
          background-color: #e5ebf2;
          padding: 10px;
          font-size: 12px;
        }
        .toggle-calculator-btn {
          position: absolute;
          top: 29px;
          right: 0;
          padding: 0;
          font-size: 15px;
          color: #5d9be3;
          text-decoration: underline;
          @media (max-width: 768px) {
            position: relative;
            top: inherit;
            margin-top: -20px;
          }
        }
        .field-input {
          height: 50px;
          color: #000000;
          font-size: 16px;
          font-weight: 400;
          padding-right: 45px;
          border-radius: 5px;
          background-position: 98% 50%;
          &::placeholder {
            color: "#888888" !important;
            font-weight: 400 !important;
          }
        }
        .date-label {
            display: none !important;
            position: absolute;
            top: -20px;
            letter-spacing: 0.5px;
            font-weight: 500;
            font-size: 10px;
            color: black;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            &.show {
              display: block !important;
            }
        }
        .invalid-input {
          border: 1px solid $ErrorColor !important;
        }
        .react-datepicker-wrapper{
          width: 100%;
        }
        .react-datepicker__input-container{
          display: block;
        }
        .date-picker, .nric-field-input {
          border: none; /* <-- This thing here */
          border: 1px solid $borderColor;
          padding: .375rem .75rem;
          padding-right: 45px;
          width: 100%;
          &:focus {
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
          }
        }
        .form-tick-date, .form-tick {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 30px;
          height: 15px;
          width: 15px;
          font-size: 14px;
          color: $verifyChecked;
          .fa-times {
            font-size: 15px;
            color: $ErrorColor !important;
          }
        }
        .tips-msg {
          position: absolute;
          bottom: -35px;
          color: $ErrorColor;
          font-size: 12px;
          white-space: nowrap;
          @media (max-width: 768px) {
            position: relative;
            bottom: inherit;
            margin: 0;
            white-space: pre-wrap;
          }
        }
        .loading-indicator {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 25px;
          width: 19px;
          height: 25px;
          img {
            width: 100%;
            filter: grayscale;
          }
        }
        .request-otp-btn {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          border: 0;
          color: #4a90e2;
          background-color: rgba(255,255,255,0);
          font-size: 14px;
          white-space: nowrap;
          left: -10px;
          text-decoration: underline;
          @media (max-width: 768px) {
            position: relative;
          }
          &:focus {
            outline: none;
          }
        }
        .product-disclosure-btn {
          font-size: 10px;
          background-color: rgba(255,255,255,0);
          border: 0;
          position: absolute;
          top: -17px;
          right: 15px;
          color: #4a90e2;
          text-decoration: underline;
          @media (max-width: 1024px) {
            top: inherit;
            bottom: -22px;
          } 
          &:focus {
            outline: none;
          }
        }

        .resend-code {
          font-size: 12px;
          border: 1px solid #cccccc;
          border-radius: 5px;
          position: relative;
          color: #cccccc;
          padding: 10px;
          margin: 0 10px;
          background-color: rgba(255, 255, 255, 0);
          width: 100px;
          cursor: text;
          &.active{
            color: $allianceBlue;
            border: 1px solid $allianceBlue;
            cursor: pointer;
          }
        }

        .tac-loading-indicator {
          position: relative;
          left: -35px;
          width: 19px;
          height: 25px;
          @media (max-width: 590px) {
            position: relative;
            top: 0px;
            left: -25px;
          }
          img {
            width: 100%;
            filter: grayscale;
          }
        }
        
        .tnc-wrapper {
          padding: 10px;
          border: 0 !important;
          background-color: rgba(255,255,255,0) !important;
          flex-direction: row !important;
          align-items: center !important;
          .card-text {
            font-size: 14px;
            margin: 0 15px;
            color: $BoldLabelColor;
            .declaration {
              cursor: pointer;
              color: $BoldLabelColor;
              text-decoration: underline;
              font-weight: 600;
            }
          }
        }
        .share-info-wrapper {
          padding: 10px;
          background-color: rgba(255,255,255,0) !important;
          .share-info-desc {
            font-size: 14px;
            color: $BoldLabelColor;
          }
          .share-info-desc-checker {
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 35px;
          }
          .share-info-input-wrapper {
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
              flex-direction: column;
            }
            .share-info-content-wrapper {
              @media (max-width: 768px) {
                margin: 10px 0;
                &.col {
                  flex: 1 1 auto;
                }
              }
              .share-info-check-wrapper {
                display: flex;
                flex-direction: row;
                .box-checker {
                  border-radius: 100px;
                }
                .box-checker-label {
                  display: block !important;
                  position: relative;
                  top: inherit;
                  right: inherit;
                  font-size: 15px;
                  font-weight: 500;
                  margin: 0 10px;
                }
              }
            }
          }
        }
        .box-checker {
          position: relative;
          // border: 1px solid #000000;
          justify-content: center;
          align-items: center;
          // color: $BgColor;
          font-size: 12px;
          border-radius: 50%;
          border: 1px solid lightgray;
          background: rgba(255,255,255,0);
          color: rgba(255,255,255,0);
          height: 23px;
          width: 23px;
          display: flex;
          cursor: pointer;
          flex-shrink: 0;
          &.box-checked {
            // background-color: $BoxCheckedColor;
            background: $allianceBlue;
            border: 4px solid #FFF;
            box-shadow: 0 0 0 1px #002559;
          }
        }
        .box-checker-box {
          position: relative;
          border: 1px solid #000000;
          justify-content: center;
          align-items: center;
          color: $BgColor;
          font-size: 12px;
          
          background: rgba(255,255,255,0);
          color: rgba(255,255,255,0);
          height: 23px;
          width: 23px;
          display: flex;
          cursor: pointer;
          flex-shrink: 0;
          &.box-checked-box {
            background-color: $BoxCheckedColor;
            background-image: url("../../assets/img/white-check.png");
            background-repeat: no-repeat;
            
            background-position: center; 
            
           
          }
        }
        .submit-more-info-form-list {
          margin-top: 20px;
          padding-left: 0 !important;
          li {
            border: 0;
            padding: 0;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 20px;
            background-color: rgba(255,255,255,0);
            list-style-type: none;
            font-weight: 400;
            .submit-more-info-form-label {
              cursor: pointer;
              .svg-inline--fa {
                margin-top: 0 !important;
              }
              font-size: 18px;
              .badge-completed {
                background-color: $CompletedColor !important;
              }
              .badge-in-progress {
                background-color: $InProgressColor !important;
              }
              .badge-not-completed {
                background-color: $NotCompleted !important;
              }
              .badge {
                font-size: 12px;
                font-weight: 500;
                color: #000000 !important;
              }
            }
          }
        }
      }
    }
  }
}

.submit-more-info-section,
.receive-money-section {
  .container {
    .mb-responsive-collapse {
      @media (max-width: 768px) {
        margin: 0 !important;
      }
    }
    .tips-warning {
      margin-top: 5px;
      margin-bottom: 0;
      color: $ErrorColor;
      font-size: 12px;
    }
    .section-description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      padding: 0;
    }
    .section-description-2 {
      font-size: 14px;
      color: $BoldLabelColor;
    }
    .section-description-3 {
      font-size: 12px;
    }
  }
}

.receive-money-section {
  button {
    width: 165px;
    height: 45px;
    font-size: 14px;
    color: $BoldLabelColor;
    border: 1px solid $BoldLabelColor;
    background-color: #ffffff;
    box-shadow: none !important;
    &:hover, &:active {
      color: #ffffff;
      background-color: $BoldLabelColor;
      box-shadow: none !important;
    }
  }
  .remove-file-button {
    height: auto;
    width: auto;
    padding: 0 16px 6px 16px;
    color: $BoldLabelColor !important;
    font-size: 12px;
    border: 0;
    background-color: rgba(255,255,255,0) !important;
    &:focus, &:hover, &:active {
      box-shadow: none !important;
    }
  }
  span {
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
  }
  .container {
    .row {
      .col {
        .submit-more-info-form-list {
          li {
            margin-bottom: 10px;
          }
          .dropzone-container {
            outline: none !important;
            user-select: none;
            .upload-field-container {
              background-color: #ffffff;
              text-align: center;
              .section-upload-description {
                margin-bottom: 0;
                padding: 13px 20px;
                font-weight: 400;
                color: $BoldLabelColor;
              }
              .upload-field-wrapper,
              .upload-field-details {
                .upload-field {
                  height: 205px;
                  margin: 0 20px 13px;
                  text-align: center;
                  border: 1px dashed lightgray;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  .upload-title {
                    margin: 10px 0 0;
                    padding: 0 10px;
                    font-size: 20px;
                    font-weight: 600;
                  }
                  .upload-description {
                    margin: 0;
                    padding: 0 10px;
                    font-size: 14px;
                  }
                  img {
                    width: 40px;
                    display: block;
                    @media (max-width: 768px) {
                      width: auto;
                      height: 35px;
                    }
                  }
                  .upload-field-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    .file-progress-wrapper {
                      display: flex;
                      flex-direction: row;
                      margin-bottom: 10px;
                      .upload-field-progress {
                        width: 280px;
                        margin: 0 0 0 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        @media (max-width: 768px) {
                          width: 200px;
                        }
                        @media (max-width: 400px) {
                          width: 150px;
                        }
                        .file-name-wrapper {
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          .file-name {
                            margin: 0;
                            font-size: 12px;
                          }
                          .fa-check {
                            color: $validColor;
                            font-size: 12px;
                          }
                          .fa-times {
                            color: $ErrorColor;
                            font-size: 12px;
                            cursor: pointer;
                          }
                          .successUploadWrapper {
                            white-space: nowrap;
                            .successUploadCompressedSize {
                              margin-right: 5px;
                              color: black;
                              font-size: 0.8rem;
                            }
                          }
                          // to replace end of string with 3 dots
                          /* mixin for multiline */
                          @mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
                            overflow: hidden;
                            position: relative;
                            line-height: $lineHeight;
                            max-height: $lineHeight * $lineCount; 
                            text-align: justify;
                            margin-right: 1em;
                            padding-right: 1em;
                            &:before {
                              content: '...';
                              position: absolute;
                              right: 0;
                              bottom: 0;
                            }
                            &:after {
                              content: '';
                              position: absolute;
                              right: 0;
                              width: 1em;
                              height: 1em;
                              margin-top: 0.2em;
                              background: $bgColor;
                            }
                          }

                          .successUploadFileName {
                            @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white);  
                          }
                        }
                        .file-valid-icon {
                          height: 10px;
                          width: 10px;
                        }
                        .file-progress-bar-indicator {
                          transition: all 0.2s;
                          width: 100%;
                          height: 5px;
                          background: $validColor;
                        }
                        .file-progress-bar {
                          width: 100%;
                          &.uploading {
                            background: rgba(208, 2, 26, 0.1);
                          }
                        }
                        .file-progress-bar-indicator {
                          width: 100%;
                          &.uploading {
                            background: $ErrorColor;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .upload-button-container {
                position: relative;
                width: 100%;
                display: flex;
                background-color: #e9e9e9;
                display: flex;
                align-items: center;
                cursor: pointer;
                .upload-button {
                  width: 150px;
                  height: 50px;
                  background-color: rgba(0, 37, 89, 0.5);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  font-size: 14px;
                }
                span {
                  margin: 0;
                  margin-left: 17px;
                  font-size: 16px;
                }
              }
            }
          }
          .upload-kwsp-container {
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid $borderColor;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
              flex-direction: column;
            }
            .left-panel-upload {
              margin: 35px 0 20px;
              padding: 0 20px;
              border-right: 1px solid $borderColor;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              @media (max-width: 768px) {
                border-right: 0;
                margin: 35px 0;
              }
              .upload-panel-logo {
                width: 88px;
                height: 42px;
              }
              h1 {
                font-size: 14px;
                font-weight: 500;
              }
              .upload-desc {
                margin-bottom: 50px;
                font-size: 12px;
                font-weight: 400;
                display: flex;
                align-items: center;
                p {
                  margin: 0 0 0 5px;
                  // white-space: nowrap;
                  white-space: pre-line;
                  text-align: center;
                }
              }
            }
            .right-panel-upload { 
              width: 100%;
              .dropzone-container {
                .upload-field-container {
                  .upload-field { 
                    margin: 0;
                    border: 0 !important;
                    width: 100%;
                    &.multi-field-container {
                      padding-left: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: flex-start;
                      overflow: hidden;
                      overflow-y: scroll;
                      width: 100%;
                      height: 100%;
                      max-height: 200px;
                      .upload-field-content-wrapper {
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        .multi-file-progress-wrapper{
                          display: block;
                          margin-bottom: 21px;
                          width: 100%;
                          .upload-field-progress-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            img {
                              width: 10px;
                              height: 12px;
                              display: block;
                              @media (max-width: 625px) {
                                display: none;
                              }
                            }
                            .upload-files-progress-inner-wrapper {
                              display: flex;
                              flex-direction: row;
                              width: 100%;
                              .upload-field-progress {
                                margin-top: -5px;
                                display: block;
                                width: 100%;
                                .file-name-wrapper {
                                  .file-name {
                                    text-align: left;
                                  }
                                }
                              }
                              .remove-file-button {
                                height: auto;
                                width: auto;
                                padding: 0 16px 6px 16px;
                                color: $BoldLabelColor;
                                font-size: 12px;
                                border: 0;
                                background-color: rgba(255,255,255,0);
                                &:focus, &:hover {
                                  outline: none;
                                  box-shadow: none;
                                }
                              }
                            }
                          }
                        }
                      }
                      .upload-title, .upload-description {
                        display: none;
                      }
                    }
                    .upload-field-content-wrapper {
                      padding: 0;
                    }
                  }
                  .upload-button-container {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .upload-nric-container {
          position: relative;
          padding: 18px;
          border-radius: 5px;
          background-color: #ffffff;
          border: 1px solid $borderColor;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .verifyy-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 20px;
            width: 20px;
            border-radius: 100px;
            background-color: $BoldLabelColor;
            display: flex;
            justify-content: center;
            align-items: center;
            .fa-check {
              color: #ffffff;
            }
          }
          .dropzone-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .nric-image {
              width: 113px;
              height: 70px;
            }
            img {
              width: 54px;
              height: 40px;
              margin-top: 17px;
            }
            h1 {
              margin: 21px 0;
              font-size: 12px;
            }
            span {
              margin-bottom: 5px;
              .fa-paperclip {
                margin-right: 5px;
              }
            }
          }
        }
      }
      .nric-col {
        min-width: 215px;
      }
    }
  }
}

.completed-receive-money-section {
  button {
    margin-bottom: 25px;
    width: 165px;
    height: 45px;
    font-size: 14px;
    color: #ffffff;
    border: 1px solid $BoldLabelColor;
    background-color: $BoldLabelColor;
    box-shadow: $boxShadow;
    &:hover, &:active {
      color: #ffffff;
      background-color: $BoldLabelColor;
      box-shadow: $boxShadow;
    }
  }
  .container {
    margin: 18px auto;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo-row {
      @media (max-width: 768px) {
        margin-bottom: 17px;
      }
      .col {
        @media (max-width: 768px) {
          display: none !important;
        }
        .logo-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 192px;
          }
          span {
            height: 27px;
            margin-top: 5px;
            margin-left: 10px;
            padding-left: 10px;
            font-weight: 500;
            font-size: 20px;
            color: $BoldLabelColor;
            border-left: 1.5px solid $BoldLabelColor;
          }
        }
      }
      .line {
        margin: 26px auto;
        width: 50px;
        height: 2px;
        background-color: #7ed321;
      }
    }
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .col {
        h1 {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            height: 18px;
            width: 18px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $BoldLabelColor;
            border-radius: 100px;
            .fa-check {
              color: #ffffff;
              font-size: 10px;
            }
          }
        }
        .head-description {
          text-align: center;
        }
        p {
          margin: 8px 0;
          font-size: 18px;
        }
        .card {
          margin-top: 17px;
          padding: 17px;
          width: 693px;
          border: 0;
          box-shadow: 0px 0px 15px 0px rgba(118, 175, 255, 0.8);
          @media (max-width: 768px) {
            width: 100%;
          }
          p {
            font-size: 16px;
            display: flex;
            span {
              width: 25px;
              height: 25px;
              margin-right: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 100%;
              }
            }
          }
          .check-status-container {
            margin-top: 0;
            margin-left: 43px;
            button {
              width: 165px;
              height: 45px;
              font-size: 14px;
              color: $BoldLabelColor;
              border: 1px solid $BoldLabelColor;
              background-color: #ffffff;
              box-shadow: $boxShadow;
              &:hover, &:active {
                color: #ffffff;
                background-color: $BoldLabelColor;
                box-shadow: $boxShadow;
              }
            }
          }
          .status-step-container {
            ul {
              position: relative;
              padding: 0;
              .line {
                position: absolute;
                top: 25px;
                bottom: 25px;
                left: 30px;
                width: 2px;
                z-index: 0;
                background-position: center;
                background-repeat: repeat-y;
                background-image: url("../../assets/img/dot-line.png");
              }
              li {
                position: relative;
                padding: 25px 0;
                list-style-type: none;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                z-index: 999;
                @media (max-width: 768px) {
                  align-items: flex-start;
                }
                &:last-child {
                  @media (max-width: 768px) {
                    align-items: flex-end;
                  } 
                }
                &:nth-child(1) {
                  span {
                    img {
                      margin-left: 5px;
                    }
                  }
                }
                span {
                  margin-right: 19px;
                  min-width: 60px;
                  min-height: 60px;
                  border-radius: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid $BoldLabelColor;
                  background-color: #ffffff;
                }
                .ovelay-white {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background-color: rgba(255, 255, 255, 0.7);
                }
              }
            }
          }
        }
        .card-description {
          box-shadow: none;
          .description {
            font-size: 12px;
            color: $BoldLabelColor;
            text-decoration: center;
            text-align: center;
          }
        }
      }
    }
  }
}

.proceedButton,
.download-print-button{
  width: 165px;
  height: 51px;
  font-size: 14px !important;
  margin-top: 30px;
  color: #ffffff !important;
  border-radius: 5px !important;
  background-color: $BoldLabelColor !important;
  &:disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
  }
}

.download-print-button {
  width: 209px;
  height: 45px;
  margin-top: 15px;
  box-shadow: $boxShadow;
}

.proceed-button-footer {
  .container {
    padding: 0 !important;
    max-width: 100% !important;
    .row {
      width: 100%;
      margin: 0;
      .col {
        width: 100%;
        padding: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        .proceed-button-wrapper {
          height: 80px;
          width: 100%;
          max-width: 1140px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @media (max-width: 1140px) {
            padding-right: 15px;
          }
          @media (max-width: 992px) {
            max-width: 960px;
          }
          .proceedButton {
            position: relative;
            height: 45px;
            margin: 0;
            box-shadow: $boxShadow;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            img {
              padding-left: 10px;
            }
            &.back-button {
              border: 0;
              color: $BoldLabelColor;
              background-color: rgba(255, 255, 255, 0) !important;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}


.calculator-modal {
  margin: 20px;
  width: 100% !important;
  max-width: 1170px !important;
  .modal-header {
    padding: 21px 33px;
    h5 {
      font-size: 18px;
    }
  }
  .modal-body {
    padding: 15px 33px;
  }
  .modal-footer {
    padding: 15px 33px;
    button {
      width: 165px;
      height: 45px;
      font-size: 14px;
      margin: 0 0 0 10px;
      background-color: $BoldLabelColor;
      box-shadow: $boxShadow;
      border: 0 !important;
    }
    .back-btn {
      color: $BoldLabelColor;
      background-color: rgba(255, 255, 255, 0) !important;
      box-shadow: none;
      border: 0;
    }
  }
}

.share-permission-modal {
  max-width: 1030px !important;
  padding: 0 15px !important;
  margin-top: 60px !important;
  .modal-content {
    .modal-header {
      padding: 10px 1em;
      border-bottom: 0;
      h5 {
        font-size: 16px;
      }
    }
    .modal-body {
      margin: 0 1em 0;
      color: $BoldLabelColor;
      font-size: 14px;
      border: 1px solid $borderColor;
      border-radius: 2px;
      overflow-y: scroll;
      max-height: calc(100vh - 250px);
    }
    .modal-footer {
      border: 0;
      button {
        width: 165px;
        height: 45px;
        font-size: 14px;
        margin: 0 0 0 10px;
        background-color: $BoldLabelColor;
        box-shadow: $boxShadow;
        border: 0 !important;
      }
      .back-btn {
        color: $BoldLabelColor;
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;
        border: 0;
      }
    }
  }
}

.signature-modal {
  .modal-content {
    .modal-footer {
      button {
        width: 165px;
        height: 45px;
        font-size: 14px;
        margin: 0 0 0 10px;
        background-color: $BoldLabelColor;
        box-shadow: $boxShadow;
        border: 0 !important;
      }
      .back-btn {
        color: $BoldLabelColor;
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;
        border: 0;
      }
    }
  }
}

.signature-buttons {
  margin-left: 20px;
  margin-bottom: 20px;
}

.display-text-label {
  position: absolute;
  top: -20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.display-text-edit-link {
  font-size: 10px;
  border: 0;
  position: absolute;
  top: -17px;
  right: 15px;
  color: #4a90e2;
  text-decoration: underline;
  text-transform: uppercase;
}
.display-text {
  line-height: 2.2 !important;
  background-color: #e9ecef !important;
}

.textfield-cancel-edit-link {
    font-size: 10px;
    border: 0;
    position: absolute;
    top: -17px;
    right: 15px;
    color: #4a90e2;
    text-decoration: underline;
    text-transform: uppercase;
}

.edit-link{
    font-size: 10px;
    border: 0;
    text-align: right;
    color: #4a90e2;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
}
