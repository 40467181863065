.card {
  box-shadow: 0px 4px 20px #8888;
  border-radius: 5px;
  background-color: white;
  width: 90%;
  padding: 20px;
  margin: 10px 0;
  margin-bottom: 300px;
}

.card-body h6 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.card-body h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.card-footer > span {
  margin-left: 20px;
  font-size: 14px;
}

.dropdown-wrapper {
  margin: 40px 0;
  max-width: 300px;
}

@media (max-width: 640px) {
  .card {
    margin: 10px auto 200px;
  }

  .dropdown-wrapper {
    width: 80%;
  }
}
