@import "../../colors.scss";
.eligibility-container {}

.heading {
    font-size: 16px;
    color: $allianceRed;
}

.heading strong {
    font-size: 20px;
}

.eligibility-row {
    padding-left: 1.5rem;
    margin-top: 1.5rem;
}

.eligibility-row>span {
    font-size: 18px;
}

.question-four-row {
    display: inline-block;
    // font-size: 1rem;
    // font-weight: 500;
}

.question-four-row>span {
    font-size: 18px;
    font-weight: 400;
}

.loan-amt {
    display: inline-flex !important;
    width: 200px !important;
}

.loan-tenure {
    display: inline-flex !important;
    width: 200px !important;
    // position: absolute !important;
    vertical-align: middle;
}

.loan-calc {
    background-image: linear-gradient(to top, #f6f8fc, #f9fafd, #fbfbfd, #fdfdfe, #ffffff);
    border: 1px solid #e7e7e7;
    border-width: 2px 0;
    margin-top: 1.5rem;
}

.loan-calc-col {
    // text-align: center;
    border: 1px solid #e7e7e7;
    border-width: 0 2px 0 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.loan-calc-col span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    padding: 0 20px;
}

.loan-calc-col h1 {
    padding-top: 15px;
    font-size: 24px;
    letter-spacing: 1px;
}

.loan-calc-disclaimer {
    font-size: 12px;
}

.loan-calc-disclaimer strong {
    display: block;
}

.question-row {}

.question-row img {
    opacity: 0.2;
    transition: all 0.2s;
}

.question-valid {
    opacity: 1 !important;
    -webkit-filter: sepia(100%) hue-rotate(90deg) saturate(400%);
    filter: sepia(100%) hue-rotate(90deg) saturate(400%);
}

.question-row span {
    font-size: 22px;
}

.eligibility-footer {
    background: #f2f8ff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.eligibility-footer-caption {
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    margin-top: 15px;
}

.eligibility-footer-caption img {
    margin-right: 10px;
}

.eligibility-footer>button {
    display: block;
}

@media (max-width: 992px) {
    .loan-calc-col {
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }

    .loan-calc-row {
        margin: 0 !important;
    }

    .loan-calc-col-last {
        border: none;
    }

    .loan-tenure {
        margin: 0 auto;
        display: inline-flex;
        width: initial !important;
        position: relative !important;
    }

    .question-four-row {
        margin-top: 30px;
    }

    .fees-container table {
        width: 600px;
    }
}

@media (max-width: 768px) {

    .loan-amt {
        left: -1rem!important;
    }

    .loan-tenure {
        left: -1rem!important;
    }

    .loan-calc-col h1 {
        white-space: nowrap
    }

    .fees-container table {
        width: 100%;
    }

    .question-four-row {
        margin: 0;
        display: block;
    }

    .question-one {
        display: block;
        margin-bottom: 5px;
    }

    .question-two {
        display: block;
        margin-bottom: 5px;
    }

    .question-three {
        display: block;
        margin-bottom: 5px;
        margin-top: -45px;
    }

    .question-four {
        display: block;
        margin-bottom: 5px;
        margin-top:10px;
    }

    .eligibility-row {
        text-align: left;
        padding: 0 !important;
        margin-left: 1rem!important;
    }
    

    .eligibility-row-two {
        margin-top: 52px;
    }

    .loan-calc {
        margin-top: 50px;
    }

    .ic-field {
        margin-left: 45px;
        margin-top: 10px;
    }

    .switch {
        margin-left: 45px;
        margin-top: 10px;
    }
}

@media (max-width: 576px) {
    .loan-calc h1 {
        font-size: 24px;
        letter-spacing: initial;
        white-space: nowrap;
        color : #2457ad;
    }

    .loan-calc-row {
        display:block!important;
        white-space: nowrap;
    }

    .loan-calc-col {
        border: none;
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }

    .loan-calc-col span{
        padding: 0 !important;   
        width:100% 
    }

    .loan-calc {
        background:none;
    }

    
}

.parent {
    position: relative;
    top: 30;
    left: 0;
    height: 500px;
    padding-top: 50px;
    @media (max-width: 1240px) {
        top: 0
      }
    @media (max-width: 764px) {
        height: 800px;
      }
}

.leftMostRectangle {
    height: 200px;
    position: relative;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 196.png");
    background-size: 300px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
      background-size: 80% auto;
      background-position: calc(35% - -25px) center;
    }
    @media (max-width: 764px) {
      background-size: 80% auto;
  }
}

.leftSecondRectangle {
    top: 80px;
    left: 150px;
    height: 200px;
    width: 200px;
    position: absolute;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 197.png");
    background-size: 200px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
        top: 135px;
        height: 100px;
        background-size: 120px;
    }
    @media (max-width: 764px) {
        top:155px;
        left: calc(10% - -25px);
        background-size: 74% auto;
        width: 100%;
        background-image: url("../../assets/img/ploan-small/FirstBottom.png");
    }
}

.middleRectangle {
    margin-top: -200px;
    height: 200px;
    position: relative;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 198.png");
    background-size: 300px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
        background-size: 80% auto;
        background-position: calc(35% - -25px) center;
    }
    @media (max-width: 764px) {
        margin-top: 50px;
        background-size: 80% auto;
  }
}

.middleSecondRectangle {
    margin-top: -200px;
    top: -40px;
    left: 150px;
    height: 200px;
    width: 200px;
    position: absolute;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 199.png");
    background-size: 200px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
        top: 5px;
        height: 100px;
        width: 120px;
        background-size: 120px;
    }
    @media (max-width: 764px) {
        margin-top: 50px;
        top:160px;
        left: calc(10% - -25px);
        background-size: 74% auto;
        width: 100%;
        background-image: url("../../assets/img/ploan-small/MiddleBottom.png");
    }
}

.rightMostRectangle {
    margin-top: -200px;
    height: 200px;
    position: relative;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 200.png");
    background-size: 300px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
        background-size: 80% auto;
        background-position: calc(35% - -25px) center;
      }
      @media (max-width: 764px) {
        margin-top: 50px;
        background-size: 80% auto;
  }
}

.rightSecondRectangle {
    margin-top: -200px;
    top: 80px;
    left: 150px;
    height: 200px;
    width: 200px;
    position: absolute;
    background-image: url("../../assets/img/ploan 1.0 images/Rectangle 201.png");
    background-size: 200px;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
        top: 135px;
        height: 100px;
        width: 120px;
        background-size: 120px;
    }
    @media (max-width: 764px) {
        margin-top: 50px;
        top:160px;
        left: calc(10% - -25px);
        background-size: 74% auto;
        width: 100%;
        background-image: url("../../assets/img/ploan-small/LastBottom.png");
    }
}

.topArrow {
    left: -30px;
    height: 100px;
    width: 200px;
    background-image: url("../../assets/img/ploan 1.0 images/Ellipse 2.png");
    background-size: 200px;
    background-repeat: no-repeat;
    z-index: 3;
    @media (max-width: 1240px) {
        margin-bottom: -30px;
    }
    @media (max-width: 764px) {
        background-image: none;
        height: 0px;
    }
}

.bottomArrow {
    left: -30px;
    height: 100px;
    width: 200px;
    background-image: url("../../assets/img/ploan 1.0 images/Ellipse 1.png");
    background-size: 200px;
    background-repeat: no-repeat;
    z-index: 3;
    @media (max-width: 1240px) {
        margin-top: 10px;
    }
    @media (max-width: 764px) {
        background-image: none;
    }
}

.arrowDown {
    @media (max-width: 764px) {
        margin: auto;
        margin-bottom: -50px;
        height: 50px;
        width: 50px;
        background-size: 25px;
        background-repeat: no-repeat;
        background-image: url("../../assets/img/ploan-small/ArrowDown.png");
    }
}

.leftMostRectangle .leftMostText {
    padding-left: 30px;
    padding-top: 20px;
    @media (max-width: 1240px) {
        padding-left: 50px;
        padding-top: 70px;
        font-size: 14px;
    }
    @media (max-width: 764px) {
        padding-left: 80px;
        padding-top: 40px;
        font-size: 20px;
        white-space: pre-wrap;
    }
}

.leftSecondRectangle .leftMostBottomText {
    padding-left: 30px;
    padding-top: 30px;
    font-weight: bold;
    @media (max-width: 1240px) {
        padding-left: 18px;
        padding-top: 19px;
        font-size: 12px;
    }
    @media (max-width: 764px) {
        padding-left: 100px;
        padding-top: 10px;
        font-size: 16px;
        white-space: pre-wrap;
        .innerTopText::after {
            content:'per month';
        }
        .innerText {
            display: none;
        }
    }
}

.middleRectangle .middleText {
    padding-left: 30px;
    padding-top: 70px;
    @media (max-width: 1240px) {
        padding-left: 50px;
        padding-top: 85px;
        font-size: 14px;
    }
    @media (max-width: 764px) {
        padding-left: 80px;
        padding-top: 40px;
        font-size: 20px;
    }
}

.middleSecondRectangle .middleSecondBottomText {
    padding-left: 30px;
    padding-top: 30px;
    font-weight: bold;
    @media (max-width: 1240px) {
        padding-left: 18px;
        padding-top: 19px;
        font-size: 12px;
    }
    @media (max-width: 764px) {
        padding-left: 100px;
        padding-top: 10px;
        font-size: 16px;
        white-space: pre-wrap;
        .innerTopText::after {
            content:'per month';
        }
        .innerText {
            display: none;
        }
    }
}

.rightMostRectangle .rightMostText {
    padding-left: 30px;
    padding-top: 20px;
    @media (max-width: 1240px) {
        padding-left: 50px;
        padding-top: 70px;
        font-size: 14px;
    }
    @media (max-width: 764px) {
        padding-left: 80px;
        padding-top: 40px;
        font-size: 20px;
        white-space: pre-wrap;
    }
}

.rightSecondRectangle .rightSecondText {
    padding-left: 30px; 
    padding-top: 30px;
    font-weight: bold;
    @media (max-width: 1240px) {
        padding-left: 18px;
        padding-top: 19px;
        font-size: 12px;
    }
    @media (max-width: 764px) {
        padding-left: 100px;
        padding-top: 10px;
        font-size: 16px;
        white-space: pre-wrap;
        .innerTopText::after {
            content:'per month';
        }
        .innerText {
            display: none;
        }
    }
}
