.alliance-navbar {
    padding: 0 !important;
    background: #ffffff !important;
}

.alliance-navbar-collapse {
    align-self: stretch;
}

.alliance-navbar-nav {
    text-transform: uppercase;
    font-size: 14px !important;
    align-self: stretch;

    /* TO COMPENSATE FOR LI BORDER BOTTOM */
    margin-top: 8px;
}

.alliance-navbar-nav > li {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 5px solid rgba(255,255,255,0);
    transition: all 0.5s;
}

.alliance-navbar-nav > li:hover {
    border-bottom: 5px solid red;
}

.is-current {
    border-bottom: 5px solid red !important;
}

.alliance-navbar-nav > li > a {
    color: #000000 !important;
    white-space: nowrap;
}

.alliance-brand {
    font-size: 16px !important;
    display: inline-flex !important;
    align-items: center !important;
}

.alliance-brand > img {
    width: 192px;
    margin: 0 5px 5px 0;
    height: 36px;
}

@media screen and (max-width: 992px) {
    .alliance-brand > span {
        display: none;
    }
}