.panel {
    background: white;
    border-radius: 5px;
    margin: 10px 0 50px;
    padding: 30px 50px;
}

.panel > h1 {
    font-size: 24px;
    margin: 0 0 30px 0;
}

.panel > ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.panel > ul > li > h2 {
    font-size: 20px;
}

.panel > ul > li {
    padding: 5px 0;
}

.elevated {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    padding: 10px 10px;
}

.elevated > div {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.elevated > div > div {
    display: inline-flex;
    min-width: 40px;
    justify-content: center;
}

.cross-sell-banner {
    padding-top: 15px;
    @media (max-width: 768px) {
        margin-left: -40px;
        margin-right: -40px;
      }
}

@media (max-width: 992px) {
    .check-status-list-fix {
        min-width: 0 !important;
    }
}

@media only screen and (max-width: 1091px) and (min-width: 769px) {
    .save-plus-qr{
        width: 14%;
        position: absolute;
        right: 17%;
    }
    .save-plus-banner{
        width: 100%;
    }
    .save-plus-banner-mobile{
        display: none;
    }
}

@media only screen and (min-width: 1092px) {
    .save-plus-qr{
        width: 15%;
        position: absolute;
        right: 15%;
    }
    .save-plus-banner{
        width: 100%;
    }
    .save-plus-banner-mobile{
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .save-plus-qr{
        display: none;
    }
    .save-plus-banner{
        display: none;
    }
    .save-plus-banner-mobile{
        width: 100%;
    }
}
