$allianceBankRed: #b62926;
$allianceBankBlue: #002559;

footer {
  width: 100%;
  border-bottom: 17px solid $allianceBankRed;
  background-color: $allianceBankBlue;
  ul {
    width: 100%;
    max-width: 768px;
    padding: 10px 0;
    margin: 0;
    font-size: 10px;
    color: #ffffff;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      padding: 0 10px;
      border-left: 1px solid #ffffff;
      a {
        color: #ffffff;
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
      @media (max-width: 768px) {
          &:nth-child(4) {
              padding: 0;
            border: 0;
          }
      }
      &:first-child,
      &:last-child {
        padding: 0;
        padding-right: 10px;
      }
      &:last-child,
      &:first-child {
        border: 0;
      }
    }
  }
}
