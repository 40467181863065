.card {
    box-shadow: 0px 4px 20px #8888;
    border-radius: 5px;
    background-color: white;
    width: 90%;
    padding: 20px;
    margin: 10px 0;
    margin-bottom: 300px;
  }
  
  .card-footer {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 640px) {
    .card {
      margin: 10px auto 200px;
    }
  }
  