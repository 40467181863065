.title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    position: relative;
    color: #000000;
    padding: 30px 0 20px;
    margin: 0 0 10px;
}

.title:before {
    content: "";
    position: absolute;
    width: 80px;
    height: 3px;
    bottom: 5px;
    border-bottom: 5px solid red;
}