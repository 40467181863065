$BoldLabelColor: #002559;
$tncColor: #283f74;
$ErrorColor: #ee495d;
$validColor: #4d9400;
$inputFocusColor: #000000;

$checkMarkBlue: #002559;
$verifyChecked: #4d9400;
$BoxCheckedColor: #002559;
$borderColor: #cccccc;
$BgColor: #f1f1f1;

$InProgressColor: #ffe1ae;
$CompletedColor: rgba(184, 233, 134, 0.5);
$NotCompleted: #c9c9c9;
$boxShadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

.initial-loan-offer-section {
    .container {
        .section-description {
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 1em;
        }

        .section-info-description-list {
            padding: 0;
            margin-bottom: 0;

            li {
                font-size: 14px;
                list-style-type: none;

                .fa-check {
                    margin: 0 12px;
                    color: $BoxCheckedColor;
                }
            }
        }

        .row {
            position: relative;

            &.mb-responsive {
                @media (max-width: 768px) {
                    margin: 0 !important;
                    flex-direction: column;

                    .col {
                        max-width: 100% !important;
                        margin: 1rem 0 !important;
                        padding: 0;
                    }
                }
            }

            .bg-overlay {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 9998;
                background-color: rgba(0, 0, 0, 0.8);
            }

            .col {
                &.index-0 {
                    z-index: 0 !important;
                }

                &.verification-col {
                    display: flex;
                    align-items: center;
                    z-index: 9999;

                    label {
                        color: #ffffff;
                    }

                    .verify-code-success {
                        font-size: 14px;
                        font-weight: 600;
                        color: $verifyChecked;
                        margin: 0 10px 0 0;
                    }

                    .fa-check {
                        color: $verifyChecked;
                    }
                }

                .toggle-calculator-btn {
                    position: absolute;
                    top: 29px;
                    right: 0;
                    padding: 0;
                    font-size: 15px;
                    color: #5d9be3;
                    text-decoration: underline;

                    @media (max-width: 768px) {
                        position: relative;
                        top: inherit;
                        margin-top: -20px;
                    }
                }

                .field-input {
                    height: 50px;
                    color: #000000;
                    font-size: 16px;
                    font-weight: 400;
                    padding-right: 45px;
                    background-position: 98% 50%;

                    &::placeholder {
                        color: "#888888";
                        font-weight: 400;
                    }
                }

                .tips-msg {
                    position: absolute;
                    bottom: -35px;
                    color: $ErrorColor;
                    font-size: 12px;
                    white-space: nowrap;

                    @media (max-width: 768px) {
                        position: relative;
                        bottom: inherit;
                        margin: 0;
                        white-space: pre-wrap;
                    }
                }

                .loading-indicator {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 25px;
                    width: 19px;
                    height: 25px;

                    img {
                        width: 100%;
                        filter: grayscale;
                    }
                }

                .request-otp-btn {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border: 0;
                    color: #4a90e2;
                    background-color: rgba(255,255,255,0);
                    font-size: 14px;
                    white-space: nowrap;
                    left: -10px;
                    text-decoration: underline;

                    @media (max-width: 768px) {
                        position: relative;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .resend-code {
                    font-size: 10px;
                    background-color: rgba(255,255,255,0);
                    border: 0;
                    position: absolute;
                    top: -17px;
                    right: 15px;
                    color: #4a90e2;

                    &:focus {
                        outline: none;
                    }
                }

                .tnc-wrapper {
                    padding: 10px;
                    border: 0 !important;
                    background-color: rgba(255,255,255,0) !important;
                    flex-direction: row !important;
                    align-items: center !important;

                    .card-text {
                        font-size: 14px;
                        margin: 0 15px;
                        color: $BoldLabelColor;

                        .declaration {
                            cursor: pointer;
                            color: $BoldLabelColor;
                            text-decoration: underline;
                            font-weight: 600;
                        }
                    }
                }

                .share-info-wrapper {
                    padding: 10px;
                    background-color: rgba(255,255,255,0) !important;

                    .share-info-desc {
                        font-size: 14px;
                        color: $BoldLabelColor;
                    }

                    .share-info-desc-checker {
                        margin-top: 10px;
                        margin-bottom: 0;
                        margin-left: 35px;
                    }

                    .share-info-input-wrapper {
                        display: flex;
                        flex-direction: row;

                        @media (max-width: 768px) {
                            flex-direction: column;
                        }

                        .share-info-content-wrapper {
                            @media (max-width: 768px) {
                                margin: 10px 0;
                            }

                            .share-info-check-wrapper {
                                display: flex;
                                flex-direction: row;

                                .box-checker {
                                    border-radius: 100px;
                                }

                                .box-checker-label {
                                    display: block !important;
                                    position: relative;
                                    top: inherit;
                                    right: inherit;
                                    font-size: 15px;
                                    font-weight: 500;
                                    margin: 0 10px;
                                }
                            }
                        }
                    }
                }

                .box-checker {
                    position: relative;
                    height: 23px;
                    width: 23px;
                    border: 1px solid #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 12px;
                    &.box-checked {
                        background-color: $BoxCheckedColor;
                        border: 4px solid #FFF;
                        box-shadow: 0 0 0 1px $BoxCheckedColor;
                    }
                }

                .submit-more-info-form-list {

                    //   margin-top: 20px;
                    li {
                        border: 0;
                        padding-left: 0;
                        background-color: rgba(255,255,255,0);

                        .submit-more-info-form-label {
                            .svg-inline--fa {
                                margin-top: 0 !important;
                            }

                            font-size: 18px;

                            .badge-completed {
                                background-color: $CompletedColor !important;
                            }

                            .badge-in-progress {
                                background-color: $InProgressColor !important;
                            }

                            .badge-not-completed {
                                background-color: $NotCompleted !important;
                            }

                            .badge {
                                font-size: 12px;
                                font-weight: 500;
                                color: #000000 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.initial-offer-row {
    background: white;
    padding: 20px;
}

.initial-offer-row {
    .col {
        >span {
            display: block;
        }

        >i {
            font-style: normal;
            display: block;
            font-size: 12px;
        }

        // .box-checker-wrapper {
        //     margin-bottom: 40px; 

        //     .box-checker {
        //         border-radius: 50%;
        //         display: inline-flex !important;
        //         margin: 0 20px;
        //     }

        //     .box-checker-label {
        //         color: $BoldLabelColor;
        //         font-weight: 600;
        //         display: inline-block;
        //     }
        // }

        .loan-offer-panel {
            &.selected {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                    0 0 8px rgba(102, 175, 233, 0.6);
                border: 1px solid rgb(180, 180, 180);
                margin: 20px 0 0;
                padding: 0 0 20px 0;
            }

            border: none;
            box-shadow: none;
            border-radius: 5px;

            .loan-offer-panel-title {
                display: flex;
                padding: 20px 0;

                .box-checker {
                    border-radius: 50%;
                    display: inline-flex;
                    margin: 0 20px;
                    flex-shrink: 0;
                }

                span {
                    display: block;
                    padding: 0 10px 0 0;
                    color: $BoldLabelColor;
                    font-weight: 600;
                }
            }

            .loan-offer-adjust-panel {
                background: rgb(249, 249, 249);
                margin: 10px 20px;
                padding: 20px;

                span {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }
                }

                .calc-wrapper {
                    margin: 10px 0 0;

                    .line-one {
                        display: inline-block;
                    }

                    .line-two {
                        display: inline-block;
                    }

                    @media (max-width: 992px) {
                        .line-one {
                            display: flex;
                            justify-content: center;
                            margin-top: 50px;
                        }

                        .line-two {
                            margin-top: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .line-two>h4 {
                            margin: 0;
                        }

                        .line-two {
                            .input-group {
                                &.mb-2 {
                                    margin-bottom: 0 !important;
                                    width: 150px !important;
                                }
                            }
                        }
                    }

                    @media (max-width: 767px) {
                        .line-one {
                            display: block;
                            justify-content: center;
                            margin-top: 50px;

                            i {
                                display: block;
                                text-align: center;
                                margin: 0 0 20px;
                            }
                        }

                        .line-two {
                            margin-top: 70px;
                            display: block;
                        }

                        .line-two>h4 {
                            margin: 0 0 20px;
                            display: block;
                            text-align: center;
                        }

                        .line-two {
                            .input-group {
                                &.mb-2 {
                                    margin-bottom: 0 !important;
                                    width: 150px !important;
                                    margin: 0 auto !important;
                                    display: flex !important;
                                }
                            }
                        }
                    }

                }

                i {
                    font-weight: 500;
                    font-size: 22px;
                    font-style: normal;
                    margin: 10px 0 0 30px;
                }

                h4 {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 22px;
                    font-style: normal;
                    margin: 10px 0 0 0;
                }
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    display: inline-block;
                    width: calc(50% - 40px);
                    margin: 0 20px;
                    padding: 8px 0;

                    span {
                        font-weight: 600;
                    }

                    i {
                        color: rgb(150, 150, 150);
                        display: inline-block;
                        text-align: right;
                        min-width: 40%;
                        font-style: normal;
                        padding: 0 10px 0 0;
                    }

                    @media (max-width: 550px) {
                        span {
                            display: block;
                            text-align: center;
                        }

                        i {
                            text-align: center;
                            min-width: 100%;
                        }
                    }

                }

                @media (max-width: 992px) {
                    li {
                        width: calc(100% - 40px);
                    }
                }

                .highlight {
                    background-color: rgb(235, 243, 251) !important;
                    border-radius: 5px;

                    i {
                        img {
                            width: 20px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//     input[type='range'] {
//       overflow: hidden;
//       width: 80px;
//       -webkit-appearance: none;
//       background-color: #b4b4b4;
//       outline: none !important;
//     }

//     input[type='range']::-webkit-slider-runnable-track {
//       height: 10px;
//       -webkit-appearance: none;
//       color: #444444;
//       margin-top: -1px;
//     }

//     input[type='range']::-webkit-slider-thumb {
//       width: 10px;
//       -webkit-appearance: none;
//       height: 10px;
//       background: #2556b1;
//       box-shadow: -100px 0 0 100px #353535;
//       cursor: pointer;
//     }

// }

// input[type="range"]::-moz-range-progress {
//   background-color: #43e5f7; 
// }
// input[type="range"]::-moz-range-track {  
//   background-color: #9a905d;
// }

// input[type="range"]::-ms-fill-lower {
//   background-color: #43e5f7; 
// }
// input[type="range"]::-ms-fill-upper {  
//   background-color: #9a905d;
// }

// excess height to improve interactive area / accessibility
$height: 30px;
$thumb-height: 13px;
$track-height: 3px;

// colours
$upper-color: #cfcfcf;
$lower-color: #414141;
$thumb-color: #002559;
$thumb-hover-color: #002559;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat rgba(255,255,255,0);
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat rgba(255,255,255,0);

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
    $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};

    @if $i==1 {
        @for $k from 2 through 1000 {
            $val: #{$val},
            webkit-slider-thumb-shadow($k);
        }
    }

    @return $val;
}

.e-range {
    background: rgba(255,255,255,0);
    display: block;
    appearance: none;
    overflow: hidden;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

// Webkit
.e-range::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
}

.e-range::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    top: 50%;
    margin-top: (-$thumb-height/2);
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
}

// Firefox
.e-range::-moz-range-track,
.e-range::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
}

.e-range::-moz-range-progress {
    background: $lower-background;
}

.e-range::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
}

// Internet Exploder
.e-range::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: rgba(255,255,255,0);
    background: rgba(255,255,255,0);
}

.e-range::-ms-fill-lower {
    background: $lower-background;
}

.e-range::-ms-fill-upper {
    background: $upper-background;
}

.e-range::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
}

.e-range:hover,
.e-range:focus {

    &::-webkit-slider-thumb {
        background-color: $thumb-hover-color;
    }

    &::-moz-range-thumb {
        background-color: $thumb-hover-color;
    }

    &::-ms-thumb {
        background-color: $thumb-hover-color;
    }
}