#chat-launcher.animated.fadeInUp{
    animation-duration:0.5s;
    animation-timing-function:ease-out
}
.chat-launcher{
    width:57px;
    height:57px;
    cursor:pointer;
    position:fixed;
    bottom:150px;
    right:40px
}
.chat-launcher .chat-intro{
    display:inline-block;
    /* min-width:125px; */
    width:auto;
    white-space:nowrap;
    padding:8px 12px;
    background-color:#fff;
    border-radius:5px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    right:0px;
    bottom:70px;
    -webkit-box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4);
    -moz-box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4);
    box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4);
    position:absolute;
    box-sizing:border-box;
    color:#F33;
    text-decoration:none;
    -webkit-font-smoothing:antialiased
}
.chat-launcher .chat-intro::after{
    content:"";
    position:absolute;
    width:0;
    height:0;
    bottom:-3px;
    right:15px;
    box-sizing:border-box;
    border:6px solid black;transform-origin:0 0;
    transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    -webkit-box-shadow:rgba(0,0,0,0.3) 1.5px 1.5px 1.5px;
    -moz-box-shadow:rgba(0,0,0,0.3) 1.5px 1.5px 1.5px;
    box-shadow:rgba(0,0,0,0.3) 1.5px 1.5px 1.5px;
    border-color:transparent #fff #fff transparent
}

.minimizedAlert{
    z-index:999
}
.minimizedAlert .chat-intro{
    animation:1s linear 1s 1 fadeInDown;
    opacity:1;
    animation:1s ease-in 0.5s 2 bounce;
    animation-iteration-count:5;
    -webkit-animation-iteration-count:5
}
.chat-spinner{
    width:60px;
    height:60px
}
.chat-intro{
    opacity:0
}
.chat-intro.chat-text{
    right:0px !important;
    bottom:68px !important
}
.chat-intro:hover{
    text-decoration:none;
}
.chat-slideup{
    animation:1s ease-in 0s 1 fadeInUp
}
#chat-icon{
    width:54px;
    height:54px;
    background:#F33;
    border-radius:50%;
    -webkit-box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4);
    -moz-box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4);
    box-shadow:0px 1px 2px 0 rgba(0,0,0,0.4)
}
.header-chat-icon{
    display: inline-block;
}
.header-chat-text{
    display: inline-block;
    font-size: 1.5rem;
    vertical-align: middle;
    padding: 10px;
}
.need-help-input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
}
/* hide input blue&red outline when click on input field*/
.need-help-input:focus, 
.need-help-input.form-control:focus,
.need-help-input.form-control.is-invalid:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
/* set autofill input background color to transparent instead blue*/
.need-help-input:-webkit-autofill,
.need-help-input:-webkit-autofill:hover,
.need-help-input:-webkit-autofill:focus,
.need-help-input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

.external-close-button {
    position: relative;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.1;
    background-color: aliceblue;
    cursor: pointer;
    float: right;
    margin: 10px;
}

/* --------------------------------
   ANIMATION
*/
.bounce{
    animation: bounce linear 0.8s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: bounce linear 0.8s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: bounce linear 0.8s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: bounce linear 0.8s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: bounce linear 0.8s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
  }
  
  @keyframes bounce{
    0% {
      transform:  translate(0px,0px)  ;
    }
    15% {
      transform:  translate(0px,-25px)  ;
    }
    30% {
      transform:  translate(0px,0px)  ;
    }
    45% {
      transform:  translate(0px,-15px)  ;
    }
    60% {
      transform:  translate(0px,0px)  ;
    }
    75% {
      transform:  translate(0px,-5px)  ;
    }
    100% {
      transform:  translate(0px,0px)  ;
    }
  }
  
  @-moz-keyframes bounce{
    0% {
      -moz-transform:  translate(0px,0px)  ;
    }
    15% {
      -moz-transform:  translate(0px,-25px)  ;
    }
    30% {
      -moz-transform:  translate(0px,0px)  ;
    }
    45% {
      -moz-transform:  translate(0px,-15px)  ;
    }
    60% {
      -moz-transform:  translate(0px,0px)  ;
    }
    75% {
      -moz-transform:  translate(0px,-5px)  ;
    }
    100% {
      -moz-transform:  translate(0px,0px)  ;
    }
  }
  
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform:  translate(0px,0px)  ;
    }
    15% {
      -webkit-transform:  translate(0px,-25px)  ;
    }
    30% {
      -webkit-transform:  translate(0px,0px)  ;
    }
    45% {
      -webkit-transform:  translate(0px,-15px)  ;
    }
    60% {
      -webkit-transform:  translate(0px,0px)  ;
    }
    75% {
      -webkit-transform:  translate(0px,-5px)  ;
    }
    100% {
      -webkit-transform:  translate(0px,0px)  ;
    }
  }
  
  @-o-keyframes bounce {
    0% {
      -o-transform:  translate(0px,0px)  ;
    }
    15% {
      -o-transform:  translate(0px,-25px)  ;
    }
    30% {
      -o-transform:  translate(0px,0px)  ;
    }
    45% {
      -o-transform:  translate(0px,-15px)  ;
    }
    60% {
      -o-transform:  translate(0px,0px)  ;
    }
    75% {
      -o-transform:  translate(0px,-5px)  ;
    }
    100% {
      -o-transform:  translate(0px,0px)  ;
    }
  }
  
  @-ms-keyframes bounce {
    0% {
      -ms-transform:  translate(0px,0px)  ;
    }
    15% {
      -ms-transform:  translate(0px,-25px)  ;
    }
    30% {
      -ms-transform:  translate(0px,0px)  ;
    }
    45% {
      -ms-transform:  translate(0px,-15px)  ;
    }
    60% {
      -ms-transform:  translate(0px,0px)  ;
    }
    75% {
      -ms-transform:  translate(0px,-5px)  ;
    }
    100% {
      -ms-transform:  translate(0px,0px)  ;
    }
  }

  /* autofill*/
@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}