$verifyChecked: #4d9400;
$ErrorColor: #ee495d;

.test {
  color: green;
}

.kyi-input-wrapper {
  position: relative;
  display: inline-block;
}

.kyi-input-wrapper + .kyi-input-wrapper {
  padding-left: 10px;
}

input {
    font-weight: 500 !important;
}

label {
  display: none !important;
  position: absolute;
  top: -20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  &.show {
    display: block !important;
  }
}

.form-tick {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 15px;
  height: 15px;
  width: 15px;
  font-size: 14px;
  color: $verifyChecked;
}