$ErrorColor: #ee495d;
$whiteColor: #ffffff;

.needHelpModal{
    .tips-msg {
        position: absolute;
        bottom: -35px;
        color: $ErrorColor;
        font-size: 12px;
        white-space: nowrap;
        @media (max-width: 768px) {
          position: relative;
          bottom: inherit;
          margin: 0;
          white-space: pre-wrap;
        }
      }
}