.loan-offer-template-container {
    background-color: white;
    overflow-y: auto;
    padding: 30px;
  
  .alliance-logo {
    img {
      width: 192px;
      margin: 0 5px 5px 0;
      //height: 36px;
    }
    span {
      font-size: 16px !important;
      color: rgba(0,0,0,.9);
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
  }

  h1 {
    padding: 0px;
    margin: 0px;
    font-size: 13px;
    font-family: arial;
    font-weight: 600;
  }
  h2 {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-family: arial;
  }
  p {
    padding: 0px;
    margin: 0px;
    font-family: arial;
  }
  ul {
    padding: 0px;
    margin: 0px;
    font-family: arial;
  }
  #banner {
    display: -ms-flex;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    font-family: arial;
    width: 1000px;

    // :nth-child(1) {
    //   margin-right: 10px;
    //   width: 500px;
    // }
    // :nth-child(2) {
    //   margin-right: 10px;
    //   text-align: right;
    //   width: 500px;

    //   img {
    //     width: 80%;
    //   }
    // }
  }
  #loan-summary {
    width: 1000px;
    text-align: center;
    font-family: arial;
    margin-bottom: 10px;
    display: -ms-flex;
    display: flex;

    :nth-child(1) {
      flex: 1;
      margin-right:10px;
    }
    :nth-child(2) {
      flex: 1;
      margin-right:10px;
    }
    :nth-child(3) {
      flex: 1;
      margin-right:10px;
    }
    :nth-child(4) {
      flex: 1;
      margin-right:10px;
    }
  }
  .loan-summary-item {
    border: 1px solid #cccccc;
    text-align: center;
    font-family: arial;
    background-color: rgb(250, 250, 250);
    padding: 10px;
  }
  #disclaimer {
    margin: 10px;
    font-family: arial;
  }
  #signature {
    font-family: arial;
    display: flex;

      .signature-paste-area {
        position: relative;
        height: 0;
        width: 650px;
    
        .signature-area {
          height: 97px;
          text-align: right;
          margin-top: 10px;
          width: 890px;

          .signature-signed-img {
            width: 130px; 
            height: 60px;    
           }
        }
        .signature-date-area {
          height: 27px;
          letter-spacing: 14px;
          text-align: right;
          width: 1016px;
      
          .date-text {
            font-size: 14px; 
            color: #000000;
            font-weight: 700;
            padding-left: 22px;
          }
        }
      }

      .signature-template {
        height: 144px;
        width: 365px;
        
        :nth-child(1) {
          width: 365px;
        }
      }
  }


  .section-title {
    display: -ms-flex;
    display: flex;
    width: 1000px;
    margin: 10px;
    font-family: arial;
    margin-bottom: 15px;
    img {
      vertical-align: initial
    }
  }
  .section-body {
    margin: 0px 5px;
    font-family: arial;

    .section-body-row {
      display: -ms-flex;
      display: flex;
      width: 1000px;

      :nth-child(1) {
        width: 200px;
        margin-right: 10px;
      }
      :nth-child(2) {
        width: 300px;
        margin-right: 10px;
      }
      :nth-child(3) {
        width: 200px;
        margin-right: 10px;
      }
      :nth-child(4) {
        width: 300px;
        margin-right: 10px;
      }
    }
    
    .key { 
      text-align: right;
      font-size: 14px;
      color: grey;
    }
    .val { 
      text-align: left;
      font-size: 14px;
      text-transform: capitalize; 
      .val-notes {
        font-size: 11px;
        color: red;
        width: 300px;
      }
    }
  }
  .val.noApplyTextTransform{
    text-transform: none;
  }
  .starIcon {
    color: red;
    font-weight: 600;
    font-size: 16px;
  }
  .box-label{
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    margin-left:10px;
  }
  .box-label-compact{
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    word-wrap: break-word;
    padding-left: -5px;
    padding-right: 5px;
    margin-left:10px;
  }
  .box-label-compact-i{
    text-align: center;
    font-size: 7px;
    font-weight: 400;
    word-wrap: break-word;
    padding-left: -5px;
    padding-right: 5px;
    margin-left:10px;
  }
  .box-value{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-left:10px;
  }
  .box-value-compact{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
  }
  .disclaimer-desc{
    font-family: arial;
    font-size: 14px;
  }
  .disclaimer-label{
    font-family: arial;
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .disclaimer-ul{
    font-size: 14px;
  }
  .tin-info {
    display: block;
    font-family: arial;
    margin-bottom: 5px;

    .index {
      width: 15px;
      margin-right: 10px;
      display: inline-block;
    }
    .taxJuris {
      width: 300px;
      margin-right: 10px;
      display: inline-block;
    }
    .tinNo {
      width: 500px;
      margin-right: 10px;
      display: inline-block;
    }
    .reasonOfTin {
      display: block;
      margin-left: 25px;
    }
  }
  .hyperlink {
    color: red;
    font-weight: 600;
    text-decoration: underline;
  }
}
