$BoldLabelColor: #002559;
$boxShadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

.error-modal {
    .modal-content {
        .error-box {
            display: flex;
            line-height: 1.3;
            .icon {
                margin-right: 9px;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
      .modal-footer {
        button {
          width: 100px;
          height: 45px;
          font-size: 14px;
          margin: 0 0 0 10px;
          background-color: $BoldLabelColor;
          box-shadow: $boxShadow;
          border: 0 !important;
        }
        .back-btn {
          color: $BoldLabelColor;
          background-color: rgba(255, 255, 255, 0) !important;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }