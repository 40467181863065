.tabBar{
    background-color: white;
}

.tabName{
    font-size: 13px !important;
    color:black !important;
}

.tab-header.nav-link{
    background-color: white;
    color: black;
    padding: 5px 30px 5px 30px;
    border-bottom: 3px solid rgba(255,255,255,0) !important;
    border-top: none;
    border-left: none;
    border-right: none;
}

.tab-header.nav-link.active{
    background-color: white;
    color: black;
    padding: 5px 30px 5px 30px;
    border-bottom: 3px solid #002559 !important;
    border-top: none;
    border-left: none;
    border-right: none;
    
}

.tabBar.nav.nav-tabs{
    background-color:rgba(255,255,255,0) !important;
    border-bottom: none
}

.nav-item{
    margin-bottom: 0px !important;
}



