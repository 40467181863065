@import "../../colors.scss";

.hero {
    height: 370px;
    background: white;
    background-image: url("../../assets/img/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 1240px) {
        background-position: calc(35% - -25px) center;
    }

    @media (max-width: 560px) {
        background-size: 300% auto;
        background-position: calc(30% - -10px) center;
        margin:-50px 0px -50px 0px;
    }
}

.heroWithoutMins {
    height: 370px;
    background: white;
    background-image: url("../../assets/img/bg-without-mins.png");
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
      background-position: calc(35% - -25px) center;
    }
    @media (max-width: 560px) {
      background-size: 300% auto;
      background-position: calc(30% - -10px) center;
      margin:-50px 0px -50px 0px;
  }
  }

.heroWtMinsCashBack  {
    height: 370px;
    background: white;
    background-image: url("../../assets/img/bg-cashback.png");
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 1240px) {
      background-position: calc(35% - -25px) center;
    }
    @media (max-width: 560px) {
      background-size: 300% auto;
      background-position: calc(30% - -10px) center;
      margin:-50px 0px -50px 0px;
  }
}

.hero-container {
    /* background-image: url("../../assets/img/background.png");
    background-size: cover; */
    height: 100%;

    @media (max-width: 560px) {
        transform: translateX(calc(100% - 510px));
    }
}

.circles {
    width: 100%;
    height: 100%;
    /* display: flex;
    flex-wrap: wrap; */
    position: relative;
}

.circles div {
    text-align: center;
    // flex-wrap: wrap;
    color: white;
    // display: grid;
    user-select: none;
    display: table;
}

.circles div>span {
    margin: auto;
    font-weight: 400;
}

.circles div>span>strong {
    display: block;
    font-weight: 400;
}

.circle-one {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #fb507d, #fe6b8f, #ff83a1, #ff99b2, #ffaec2);
    font-size: 22px;
    position: absolute;
    left: 90px;
    top: 30px;
    span {
        display: table-cell;
        vertical-align: middle;
    }
}

.circle-two {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #42a3e8, #4bb3ee, #5bc2f4, #6ed1f8, #83e0fd);
    font-size: 15px;
    position: absolute;
    left: 315px;
    top: 45px;
    span {
        display: table-cell;
        vertical-align: middle;
    }
}

.circle-three {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #4281e8, #4595f0, #53a9f6, #69bbfa, #83cdfd);
    font-size: 15px;
    position: absolute;
    left: 260px;
    bottom: 20px;
    span {
        display: table-cell;
        vertical-align: middle;
    }
}

.circle-four {
    text-transform: uppercase;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #4281e8, #4391f0, #4aa1f6, #57b0fb, #68bfff);
    font-size: 15px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -60px;
    span {
        display: table-cell;
        vertical-align: middle;
    }
}

.circle-four i {
    font-style: normal;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 25px;
}

.apply-now {
    background-image: linear-gradient(90deg, #FD1062 0%, #FF7C8D 48%, #FD1664 100%);
    position: absolute;
    left: 65%;
    top: 30px;
}

.apply-now > span {
    display: block;
    padding: 8px 20px;
}

.apply-now > i {
    display: block;
    font-style: normal;
    background: white;
    color: black;
    padding: 8px 20px;
    transition: all 0.2s;
    cursor: pointer;
}

.apply-now > i:hover {
    background: $allianceBlue;
    color: white;
}

.flash {
    position: fixed;
    top: 63px;
    background: #243869;
    color: white;
    width: 100%;
    padding: 10px 0;
    z-index: 100;
    font-size: 16px;
}

.flash-container {
    display: flex;
    align-items: center;
}

.flash-container img {
    margin-right: 10px;
}

.flash-container button {
    background: rgba(255,255,255,0);
    padding: 8px 55px;
    margin: 0 20px;
}

.flow-container {}

.flow-container>h1 {
    font-size: 18px;
    font-weight: 600;
    color: $allianceRed;
}

.flow-container>h2 {
    font-size: 16px;
}

.flow-container>h2>strong {
    text-transform: uppercase;
}

.flow-container>img {
    height: 200px;
    user-select: none;
}

.eligibility-container {}

.eligibility-container>h1 {
    font-size: 16px;
    color: $allianceRed;
}

.eligibility-container>h1>strong {
    font-size: 18px;
}

.eligibility-row {
    padding-left: 1.5rem;
    margin-top: 1.5rem;
}

.eligibility-row>span {
    font-size: 18px;
}

.question-four-row {
    font-size: 18px;
    display: inline-block;
}

.loan-amt {
    display: inline-flex !important;
    width: 200px !important;
}

.loan-tenure {
    display: inline-flex !important;
    width: 200px !important;
    // position: absolute !important;
}

.loan-calc {
    background-image: linear-gradient(to top, #f6f8fc, #f9fafd, #fbfbfd, #fdfdfe, #ffffff);
    border: 1px solid #e7e7e7;
    border-width: 2px 0;
    margin-top: 1.5rem;
}

.loan-calc-col {
    text-align: center;
    border: 1px solid #e7e7e7;
    border-width: 0 2px 0 0;
    margin: 20px 0;
}

.loan-calc-col span {
    font-size: 12px;
    font-weight: 500;
}

.loan-calc-col h1 {
    font-size: 28px;
    letter-spacing: 1px;
}

.loan-calc-disclaimer {
    font-size: 12px;
}

.loan-calc-disclaimer strong {
    display: block;
}

.question-row {
    
}

.question-row img {
    opacity: 0.2;
    transition: all 0.2s;
}

.question-valid {
    opacity: 1 !important;
    -webkit-filter: sepia(100%) hue-rotate(90deg) saturate(400%);
    filter: sepia(100%) hue-rotate(90deg) saturate(400%);
}

.question-row span {
    font-size: 18px;
}

.eligibility-footer {
    background: #f2f8ff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.eligibility-footer-caption {
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    margin-top: 15px;
}

.eligibility-footer-caption img {
    margin-right: 10px;
}

.eligibility-footer>button {
    display: block;
}

.ic-field {
    margin-left: 10px;
    width: 250px !important;
    display: inline-block !important;
}

.radio-question {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.radio-question>span {
    font-size: 16px;
    font-weight: 500;
}

.radio-question>span>strong {
    font-size: 18px;
    font-weight: 500;
}

.check {
    border-radius: 50%;
    border: 1px solid lightgray;
    background: rgba(255,255,255,0);
    color: rgba(255,255,255,0);
    height: 20px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    flex-shrink: 0;
}

.check-active {
    background: $allianceBlue;
    border: 4px solid #FFF;
    box-shadow: 0 0 0 1px #002559;
}

.switch {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-left: 1rem;
}

.switch-label {
    color: grey;
    font-size: 16px !important;
    padding: 5px 10px;
    border-radius: 5px 0 0 5px;
    border: 1px solid lightgray;
    border-width: 1px 0 1px 1px;
}

.switch-label+.switch-label {
    border: 1px solid lightgray;
    border-width: 1px 1px 1px 1px;
    border-radius: 0 5px 5px 0;
}

.switch-active {
    color: white;
    background: $allianceBlue;
    overflow: hidden;
}

.fees-container>h2 {
    font-size: 16px;
}

.fees-container table {
    border-collapse: collapse;
    width: 800px;
}

.fees-container td,
.fees-container th {
    text-align: left;
    padding: 18px 8px;
    font-size: 15px;
    font-weight: 600;
}

.fees-container th {
    text-transform: uppercase;
    color: #666;
    border: 1px solid #fcd1d2;
    border-width: 0 0 3px;
    font-weight: 400;
}

.fees-container td span {
    display: block;
    font-size: 12px;
    color: #b9b9b9;
}

.fees-container tr:nth-child(even) {
    background-color: #f6f7f9;
}

.docs-btn {
    background-color: rgba(255,255,255,0) !important;
    border: 2px solid #00326B;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    padding: 10px 25px;
    display: table;
    margin: 0 0 20px !important;
    a, a:hover {
        text-decoration: none !important;
        color: $allianceBlue;
    }
}

.privacyPolicy {
    a, a:hover {
        text-decoration: underline !important;
        color: #000000;
    }
}

@media (max-width: 1200px) {
    .apply-now {
        left: 65%;
    }
}

@media (max-width: 992px) {
    .apply-now {
        left: 70%;
    }

    .loan-tenure {
        margin: 0 auto;
        display: inline-flex;
        width: initial !important;
        position: relative !important;
    }

    .question-four-row {
        margin-top: 30px;
    }

    .fees-container table {
        width: 600px;
    }
}

@media (max-width: 768px) {
    .fees-container table {
        width: 100%;
    }

    .question-four-row {
        margin: 0;
        display: block;
    }

    .loan-calc-col {
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }

    .loan-calc-row {
        margin: 0 !important;
    }

    .loan-calc-col-last {
        border: none;
    }

    .question-one {
        display: block;
        margin-bottom: 5px;
    }

    .question-two {
        display: block;
        margin-bottom: 5px;
    }

    .question-three {
        display: block;
        margin-bottom: 5px;
    }

    .question-four {
        display: block;
        margin-bottom: 5px;
    }

    .eligibility-row {
        text-align: center;
        padding: 0 !important;
    }

    .eligibility-row-two {
        margin-top: 52px;
    }

    .loan-calc {
        margin-top: 80px;
    }

    .ic-field {
        margin-left: 45px;
        margin-top: 10px;
    }

    .switch {
        margin-left: 45px;
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .apply-now {
        display: none !important;
    }

    .circles {
        display:none;
    }
}

@media (max-width: 576px) { 
    .loan-calc h1 {
        font-size: 24px;
        letter-spacing: initial; 
    }

    .ic-field {
        margin-left: 0px;
        margin-top: 10px;
    }
}

.google-facebook-ad {
    color: #cccccc !important;
    font-size: 12px;

    ul{
        margin-bottom: 0px !important;
    }
}
