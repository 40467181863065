@import "colors.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

a.anchor {
  display: block;
  position: relative;
  top: -63px;
  visibility: hidden;
  height: 0px;
}

.container-small,
.container-med {
  max-width: 100% !important;
}

/* REACT SELECT STYLING */
.year-select-container {
  width: 70px;
}

.year-select__control {
  max-height: 38px;
  border-radius: 4px 0 0 4px !important;
}

.loan-select-container {
  width: 200px;
}

.employment-select-container {
  width: 250px;
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}

.employment-select__placeholder {
  white-space: nowrap;
}

/* REACT SELECT STYLING END */

.ab-btn {
  background: $allianceBlue;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px !important;
  padding: 10px 55px;
  border-radius: 5px;
  outline: none !important;
}

.ab-btn:disabled {
  background: #8a93a0;
}

/* added by kate according the code given by UI Vendor (inside storybook but not inside project code) at 14/05/2019 */
.secondaryButton {
  width: 165px;
  height: 51px;
  font-size:14px !important;
  margin-top: 30px;
  color: #002559 !important;
  border-radius: 5px !important;
  border: 1px solid #00326B;
  background-color: rgba(255,255,255,0) !important;
}

/* added by kate according the code given by UI Vendor (inside storybook but not inside project code) at 14/05/2019 */
.cancelButton {
  font-size:14px !important;
  padding: 6px 12px;
  width: 165px;
  height: 51px;
  margin-top:40px;
}

.z-index-reset {
  z-index: 0 !important;
}

.tooltip-bubble {
  min-width: 240px;
  max-width: 410px;
  position: absolute;
  z-index: 10;
}

.tooltip-bubble::after {
  content: '';
  position: absolute;
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.tooltip-top::after {
  border-left: 9px solid rgba(255,255,255,0);
  border-right: 9px solid rgba(255,255,255,0);
  border-top: 9px solid rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.tooltip-bottom::after {
  border-left: 9px solid rgba(255,255,255,0);
  border-right: 9px solid rgba(255,255,255,0);
  border-bottom: 9px solid rgba(0, 0, 0, 0.7);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.tooltip-left::after {
  border-left: 9px solid rgba(0, 0, 0, 0.7);
  border-top: 9px solid rgba(255,255,255,0);
  border-bottom: 9px solid rgba(255,255,255,0);
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.tooltip-right::after {
  border-right: 9px solid rgba(0, 0, 0, 0.7);
  border-top: 9px solid rgba(255,255,255,0);
  border-bottom: 9px solid rgba(255,255,255,0);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tooltip-message {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  line-height: 1.4;
  padding: .75em;
  text-align: center;
}

.tooltip-error {
  width: 100%;
  font-size: 15px;
}

.tooltip-error-message {
  padding: 5px 5px;
  border-radius: 5px;
  background: #EE495D;
  /* letter-spacing: 1px; */
}

.tooltip-error::after {
  border-top: 9px solid #EE495D;
}

.form-control::placeholder {
  font-weight: 400;
}

@media (min-width: 1200px) {
  .container-small {
    width: 700px !important;
  }

  .container-med {
    width: 1500px !important;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 500px !important;
  }

  .container-med {
    width: 1170px !important;
  }
}

@media (min-width: 768px) {
  .container-small {
    width: 300px !important;
  }

  .container-med {
    width: 970px !important;
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    margin: 0 0 0 auto !important;
  }

  .navbar>.container,
  .navbar>.container-fluid {
    justify-content: unset !important;
  }
}

@media (max-width: 450px) {
  .employment-select-container {
    width: 180px;
  }
}