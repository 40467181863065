$allianceBankBlue: #002559;
$navColor: #1a1919;
$navFontSize: 12px;
$disabledcolor: #CCCCCC;

.fixed-top {
  border-bottom: 1px solid #ddd;
}

.nav-bar-b, .nav-bar-c {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    .secure-application-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1vw;
      margin: auto;
      display: flex;
      align-items: center;
      color: #000000;
      span {
        font-size: 12px;
        margin-left: 5px;
        text-decoration: none;
        text-transform: uppercase;
      }
      img {
        margin: 0 5px 0 0;
      }
      @media (max-width: 1200px) {
        span {
          display: none;
        }
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .secure-application-icon {
        left: inherit;
        right: 1vw;
        img {
          margin: 0 0 0 5px;
        }
    }
    .navbar-wrapper {
        position: relative;
        width: 100%;
        height: 60px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-alliance-icon {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 25px;
          height: auto;
          display: block;
          @media (max-width: 1090px) {
            left: 45px;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
        .progress-bar-list {
          margin-bottom: 15px;
            .RSPBprogressBar {
                width: 100%;
                max-width: 600px;
                min-width: 415px;
                height: 2px;      
                @media (max-width: 768px) {
                  min-width: 295px;
                }
                .RSPBstep {
                  position: absolute;
                  right: -50px;
                  top: 0;
                  bottom: 0;
                    .progress-bar-step {
                        text-transform: uppercase;
                        margin-top: 15px;
                        // flex-direction: column;
                        // justify-content: center;
                        // align-items: center;
                       
                        .circle-wrapper  {
                            // position: absolute;
                            font-size: 8px;
                            color: $disabledcolor;
                            width: 16px;
                            margin: 5px auto;
                            
                            &.accomplished {
                                padding: 3px;
                                border-radius: 50px;
                                color: $allianceBankBlue;
                                border: 1px solid $allianceBankBlue;
                                background-color: #ffffff;
                            }
                        }
                        span {
                            margin-top: 20px;
                            color: $disabledcolor;
                            white-space: nowrap;
                            font-size: 9px;
                            &.accomplished-txt {
                                color: $allianceBankBlue;
                            }
                        }
                    }
                }
            }
        }
    }
}
.nav-bar-b {
  @media (max-width: 640px) {
    display: none;
  }
}

@media (max-width:640px) {

.navbar-brand {
  display: inline-flex!important;

  }
}

.nav-bar-b-fixed-height-mb-responsive{
  @media (max-width: 768px) {
    height: 100px !important;
  }
  @media(max-width: 640px) {
    height: 85px !important;
  }
}

.nav-bar-b-mb {
  display: none;
  width: 100%;
  @media (max-width: 767px) {
    display: block;
    .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 170px;
        @media (max-width: 460px) {
          width: 140px;
        }
      }
      span {
        height: 27px;
        margin-top: 5px;
        margin-left: 10px;
        padding-left: 10px;
        font-weight: 500;
        color: #212529;
        border-left: 1.5px solid #212529;
        @media (max-width: 460px) {
          margin-top: 2px;
          height: 23px;
          font-size: 17px;
        }
        @media (max-width: 360px) {
          font-size: 15px;
          
        }
      }
    }
    .breadcrumb-container {
      display: none;
      @media(max-width: 640px) {
        display: block;
      }
      ol {
        margin: 0;
        padding: 15px 10px 7px;
        background-color: #f1f1f1;
        li {
          font-size: 14px;
          color: $allianceBankBlue;
          &:nth-child(n+2) {
            &:before {
              content: ">";
            }
          }
          &.active {
            color: #888888 !important
          }
        }
      }
    }
  }
}

.navbar-header.steps {
  justify-content: center;
  display: flex;
  position: relative;
}

.nav-bar-c {
  width: 100%;
  height: 63px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  .container {
    @media (max-width: 768px) {
      display: flex;
      // justify-content: center;
      align-items: center;
    }
    .navbar-brand {
      @media (max-width: 1130px) {
        margin-left: 50px;
      }
      @media (max-width: 768px) {
        margin: inherit;
      }
    }
    .menu-secure-application {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 10px;
      bottom: 0;
      top: 0;
      margin: auto;
      height: 25px;
      color: #000000;
      span{
        padding-left: 5px;
        padding-right: 5px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
      }
      @media (max-width: 1200px) {
        span {
          display: none;
        }
      }
      
      @media (max-width: 640px) {
        display: none;
      }
      img {
        margin: 0 5px;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .menu-secure-application {
      left: inherit;
      right: 10px;
    }
  }
}
.progress-steps .step {
  /* width: 25%; */
  background: rgba(255,255,255,0);
}

.progress-steps .bar {
  position: absolute;
  width: calc(100% - 20px);
  border: 2px solid #e9e9e9;
  z-index: -1;
  top: 17px;
  left: 8px;
}

.progress-steps .bar-progress {
  position: absolute;
  border: 2px solid #002559;
  z-index: -1;
  top: 17px;
  left: 8px;
}

.bar-fix {
  left: 15px !important;
}

.bar-fix-two {
  width: calc(100% - 10px) !important;
}

.bar-fix-three {
  width: calc(100% - 35px) !important;
}

.past {
  color: #002559 !important;
}

.past > .step-indicator > .circle {
  background: #002559 !important;
}

.step {
  display: flex;
}

.step.active {
  color: #002559;
}

.step {
  color: #e9e9e9;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step span {
  text-transform: uppercase;
  font-size: 10px;
  user-select: none;
  letter-spacing: 2px;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
}

.step.active span {
  position: initial;
  bottom: initial;
}

.step .circle {
  background-color: #e9e9e9;
  border-radius: 50%;
  /* height: 9px;
    width: 9px; */
  padding: 3px;
  margin-bottom: 12px;
  padding: 4px;
}

.step.active .circle {
  background-color: #002559;
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px #002559;
  /* height: 15px;
    width: 15px; */
  margin-bottom: 0;
  padding: 3px;
}

.nav-bar-a {
  @media (max-width: 1200px) {
    .navbar-toggler {
      position: absolute;
      right: 2vw;
      top: 10px;
    }
  }
  .navbar-brand {
    @media (max-width: 1130px) {
      margin-left: 50px;
      margin-top: 5px;
    }
    @media (max-width: 768px) {
      margin: inherit;
    }
  }
  @media (max-width: 767px) {
    padding: 0 15px !important;
    .navbar-brand {
      margin: 0;
    }
  }
}

@media (max-width: 850px) {
  .navbar-start {
    display: none;
  }

  .navbar-end {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-nav > .active > a {
    /* border-bottom: 5px solid transparent !important; */
  }

  .navbar-nav a:hover {
    /* border-bottom: 5px solid transparent !important; */
  }

  .navbar-nav {
    background: white;
  }
}

@media (max-width: 550px) {
  .navbar-brand {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 850px) and (min-width: 550px) {
  .logo-form {
    margin-left: -20px;
  }

  .progress-steps {
    max-width: 70%;
  }

  .step-indicator span {
    font-size: 7px;
  }
}

@media only screen and (max-width: 550px) and (min-width: 401px) {
  .logo-form {
    margin-left: 5px;
  }

  .progress-steps {
    max-width: 70%;
  }

  .step-indicator span {
    font-size: 6px;
    margin: 5px;
  }
}

@media only screen and (max-width: 401px) {
  .logo-form {
    margin-left: 5px;
  }

  .progress-steps {
    max-width: 70%;
  }

  .step-indicator span {
    font-size: 6px;
    margin: 5px;
  }
}


@media (max-width: 575px) {
  .navbar-toggler {
    right: 5vw !important;
  }
  #navitem {
    margin-left:25px !important;
  }
}

.landing-logout-wrapper {
  position: absolute;
  top: 15px;
  bottom: 0;
  right: 1vw;
}

.logout-wrapper {
  margin-left: 5px !important;
}

.logout-btn {
  color: #002559 !important;
  padding: 5px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  border-radius: 5px !important;
  border: 1px solid #00326B !important;
  background-color: rgba(255,255,255,0) !important;
}

.menu-left-button{
  position: absolute;
  left: 5px;
  padding-left: 5px !important;
  top: 15px;
  bottom: 0;

  .menu-left-text {
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 5px !important;
    border: 1px solid #00326B !important;
    color: #002559 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    
    @media (max-width: 768px) {
      display: none;
    }
  }
  @media (max-width: 640px) {
    display: none;
  }
  img {
    margin: 0 5px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
