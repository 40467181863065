@import "../../colors.scss";
$ErrorColor: #ee495d;
$Blue: $allianceBlue;
$BlueIconBg: #284074;
$verifyChecked: #4d9400;
$borderColor: #cccccc;

.hero {
  height: 370px;
  background: white;
  background-image: url("../../assets/img/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1240px) {
    background-position: calc(35% - -25px) center;
  }
  @media (max-width: 560px) {
    background-size: 300% auto;
    background-position: calc(30% - -10px) center;
    margin:-50px 0px -50px 0px;
}
}

.heroWithoutMins {
  height: 370px;
  background: white;
  background-image: url("../../assets/img/bg-without-mins.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1240px) {
    background-position: calc(35% - -25px) center;
  }
  @media (max-width: 560px) {
    background-size: 300% auto;
    background-position: calc(30% - -10px) center;
    margin:-50px 0px -50px 0px;
}
}

.heroWtMinsCashBack  {
  height: 370px;
  background: white;
  background-image: url("../../assets/img/bg-cashback.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1240px) {
    background-position: calc(35% - -25px) center;
  }
  @media (max-width: 560px) {
    background-size: 300% auto;
    background-position: calc(30% - -10px) center;
    margin:-50px 0px -50px 0px;
}
}

.heroCashbackWithoutMins {
  height: 370px;
  background: white;
  background-image: url("../../assets/img/bg-cashback-without-mins.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1240px) {
    background-position: calc(35% - -25px) center;
  }
  @media (max-width: 560px) {
    background-size: 300% auto;
    background-position: calc(30% - -10px) center;
    margin:-50px 0px -50px 0px;
}
}

.disc span {
  color: red;
}

.disc {
  margin-top:-200px;
  margin-bottom:20px;
}

.hero-container {
  /* background-image: url("../../assets/img/background.png");
  background-size: cover; */
  height: 100%;
  @media (max-width: 560px) {
    transform: translateX(calc(100% - 510px));
    display:none;
  }
}

.circles {
  width: 100%;
  height: 100%;
  /* display: flex;
    flex-wrap: wrap; */
  position: relative;
}

.circles div {
  text-align: center;
  // flex-wrap: wrap;
  color: white;
  // display: grid;
  user-select: none;
  display: table;
}

.circles div > span {
  margin: auto;
  font-weight: 400;
}

.circles div > span > strong {
  display: block;
  font-weight: 400;
}

.circle-one {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #fb507d,
    #fe6b8f,
    #ff83a1,
    #ff99b2,
    #ffaec2
  );
  font-size: 22px;
  position: absolute;
  left: 90px;
  top: 30px;
    span {
      display: table-cell;
      vertical-align: middle;
  }
}

.circle-two {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #42a3e8,
    #4bb3ee,
    #5bc2f4,
    #6ed1f8,
    #83e0fd
  );
  font-size: 15px;
  position: absolute;
  left: 315px;
  top: 45px;
    span {
      display: table-cell;
      vertical-align: middle;
  }
}

.circle-three {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #4281e8,
    #4595f0,
    #53a9f6,
    #69bbfa,
    #83cdfd
  );
  font-size: 15px;
  position: absolute;
  left: 260px;
  bottom: 20px;
    span {
      display: table-cell;
      vertical-align: middle;
  }
}

.circle-four {
  text-transform: uppercase;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #4281e8,
    #4391f0,
    #4aa1f6,
    #57b0fb,
    #68bfff
  );
  font-size: 15px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -60px;
    span {
      display: table-cell;
      vertical-align: middle;
  }
}

.circle-four i {
  font-style: normal;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 25px;
}

.min-height {
  min-height: 500px;
}
.check-status-container {
  margin-top: 40px;
  .col {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 590px) {
      flex-direction: column;
    }
    &.resend-code-info {
      font-size: 12px; 
      margin-left: 56px;
      @media (max-width: 590px) {
        margin-left: 0px;
      }
    }
    &.check-status-input-row-1 {
      .nric-wrapper {
        position: relative;
        @media (max-width: 590px) {
          width: 100%;
        }
       }
      @media (min-width: 640px) {
        &:before {
          margin-right: 21px;
          width: 35px;
          height: 25px;
          content: "";
          display: block;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../../assets/img/nric.png);
        }
      }
    }
    &.check-status-input-row-2 {
      @media (min-width: 640px) {
        // max-width: 345px;
        &:before {
          margin-right: 21px;
          width: 35px;
          height: 30px;
          content: "";
          display: block;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../../assets/img/phone.png);
        }
      }
      @media (max-width: 590px) {
        .kyi-input-wrapper {
          width: auto !important;
          display: flex;
          align-items: center;
          .input-field {
            height: 50px;
            width: 260px;
          }
        }
      }
      .tac-loading-indicator {
        position: relative;
        left: -35px;
        width: 19px;
        height: 25px;
        @media (max-width: 590px) {
          position: relative;
          top: -38px;
          left: 65px;
        }
        img {
          width: 100%;
          filter: grayscale;
        }
      }
    }
    .title {
      font-size: 26px;
      font-weight: 400;
    }

    .input-Wrapper {
      @media (max-width: 590px) {
        width: 100% !important;
      }
    }

    .kyi-input-wrapper {
      width: auto !important;
      display: flex;
      align-items: center;
      @media (max-width: 590px) {
        width: 100% !important;
        .input-Wrapper {
          width: 100% !important;
        }
        .input-field {
          width: 100% !important;
        }
      }
      .input-field {
        height: 50px;
        width: 260px;
        color: #000000 !important;
        font-weight: 500 !important;
      }
    }
    .field-input {
      height: 50px;
      color: #000000 !important;
      font-size: 16px;
      font-weight: 500 !important;
      padding-right: 45px;
      border-radius: 5px;
      background-position: 98% 50%;
      &::placeholder {
        color: "#888888" !important;
        font-weight: 400 !important;
      }
    }
    .date-label {
        display: none !important;
        position: absolute;
        top: -20px;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-size: 10px;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        &.show {
          display: block !important;
        }
    }
    .invalid-input {
      border: 1px solid $ErrorColor !important;
    }
    .nric-field-input {
      border: none; /* <-- This thing here */
      border: 1px solid $borderColor;
      padding: .375rem .75rem;
      padding-right: 45px;
      font-weight: 400;
      min-width: 260px;
      max-width: 260px !important;
      @media (max-width: 590px) {
        min-width: 100%;
        width: 100%;
      }
      &:disabled {
        background-color: #e9ecef !important;
      }
      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
      }
    }
    .form-tick {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 15px;
      height: 15px;
      width: 15px;
      font-size: 14px;
      color: $verifyChecked;
    }
    .resend-code {
      font-size: 12px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      position: relative;
      color: #cccccc;
      padding: 10px;
      margin: 0 10px;
      background-color: rgba(255, 255, 255, 0);
      width: 100px;
      cursor: text;
      &.active{
        color: $allianceBlue;
        border: 1px solid $allianceBlue;
        cursor: pointer;
      }
      @media (max-width: 590px) {
        margin: 10px 0;
        width: 100% !important;
      }
    }
    .loading-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 25px;
      width: 19px;
      height: 25px;
      img {
        width: 100%;
        filter: grayscale;
      }
    }
    .tips-msg {
      position: absolute;
      bottom: -35px;
      color: $ErrorColor;
      font-size: 12px;
      white-space: nowrap;
      @media (max-width: 768px) {
        position: relative;
        bottom: inherit;
        margin: 0;
        white-space: pre-wrap;
      }
    }
    .form-button {
      width: 161px !important;
      height: 50px !important;
      font-size: 14px;
      margin: 0 0 0 21px;
      background-color: $BlueIconBg;
      &:disabled {
        opacity: 0.3 !important;
      }
      @media (max-width: 590px) {
        margin: 10px 0;
        width: 100% !important;
      }
    }
    .continue-button {
      width: 161px !important;
      height: 50px !important;
      font-size: 14px;
      margin: 0 0 0 0;
      background-color: $BlueIconBg;
      &:disabled {
        opacity: 0.3 !important;
      }
      @media (max-width: 590px) {
        margin: 10px 0;
        width: 100% !important;
      }
    }
    .card {
      height: 52px;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: 0px 0px 15px 0px rgba(118, 175, 255, 0.8);
      @media (max-width: 990px) {
        height: auto;
        padding: 10px;
      }
      .icon {
        margin-right: 9px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .description {
        display: flex;
        display: inline;
        line-height: 1.3;
      }
    }
    .check-status-list-wrapper {
      position: relative;
      width: 100%;
      max-width: 700px;
      margin-left: 68px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 990px) {
        margin: 0;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      .line {
        position: absolute;
        width: 100%;
        height: 1px;
        min-height: 1px;
        max-width: 500px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 40px;
        margin: auto;
        background-color: #000000;
        @media (max-width: 768px) {
          display: none;
        }
        ul {
          position: absolute;
          width: 100%;
          top: -1px;
          bottom: 0;
          margin: auto;
          padding: 0;
          display: flex;
          align-items: center;
          li {
            position: relative;
            list-style-type: none;
            width: 100%;
            .status-label {
              position: absolute;
              top: -25px;
              bottom: 0;
              margin: auto;
              left: 0;
              right: 0;
              text-align: center;
              font-size: 12px;
              font-weight: 500;
            }
            img {
              position: absolute;
              top: -5px;
              left: calc((170px / 2) - 7px);
            }
          }
        }
      }
      .check-status-list-active {
        transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
        z-index: 99999999;
        cursor: pointer;
        &:hover {
          border-radius: 5px;
          box-shadow: 0 1px 8px 0 #b7d5ff;
          border: solid 1px #aaaaaa;
          background-color: #ffffff;
          transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
          .check-status-btn {
            color: #ffffff;
            background-color: $Blue;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
            a { 
              color: #ffffff;
            }
          }
        }
      }
      .check-status-list {
        padding-top: 20px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 170px;
        max-width: 170px;
        z-index: 999;
        border: 1px solid rgba(255,255,255,0);
        @media (max-width: 768px) {
          margin: 15px 0;
          .check-status-label {
            white-space: nowrap;
          }
        }
        &.disabled {
          pointer-events: none;
          .check-status-list-item {
            .check-status-img,
            .check-status-label {
              opacity: 0.5;
            }
          }
        }
        &:last-child {
          .check-status-icon {
            background-color: rgba(255,255,255,0);
            padding: 0;
            img {
              width: 100%;
            }
          }
        }
        .check-status-list-item {
          position: relative;
          width: 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .check-status-img {
            position: relative;
            width: 90px;
            height: 80px;
            img {
              position: absolute;
              left: 8px;
            }
          }
          .check-status-icon-wrapper {
            position: relative;
            .check-status-icon {
              position: relative;
              margin: 14px 0;
              padding: 10px;
              width: 30px;
              height: 30px;
              border-radius: 100px;
              background-color: $BlueIconBg;
              color: #ffffff;
              font-size: 15px;
              font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99999;

              &.disabled{
                background-color: #94a0ba;
              }
            }
            .check-status-icon-2 {
              padding: 0;
              background-color: rgba(255,255,255,0);
            }
          }
          .check-status-label {
            min-height: 48px;
            font-size: 14px;
            text-align: center;
            font-weight: 500;
            color: #002559;
            line-height: 1.2;
          }
        }
        .check-status-btn {
          position: relative;
          width: 170px;
          height: 51px;
          margin-top: 29px;
          padding: 0 !important;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $Blue;
          border-radius: 5px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
          a {
            text-decoration: none;
          }
          &:before {
            position: absolute;
            left: 15px;
            content: "";
            display: block;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-size: 105%;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../assets/img/exclamation-circle-info.png);
            background-color: #ffffff;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .status-time-spend-mb-responsive {
        display: none;
        @media (max-width: 768px) {
          position: relative;
          display: block;
          padding: 2.5px 30px;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          white-space: nowrap;
          span {
            margin-right: 80px;
            font-size: 14px;
          }
          &:after {
            position: relative;
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../assets/img/arrow-down.png');
            right: 45%;
          }
          &:before {
            position: relative;
            content: "";
            display: block;
            width: 1px;
            height: 70px;
            background-color: #888888;
            left: 50%;
          }
        }
      }
    }
  }
}
.check-status-again-button{
    position: relative;
    width: 170px;
    height: 51px;
    margin-top: 29px;
    padding: 0 !important;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $Blue;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    color: $Blue;
    background-color: #ffffff
}
